'use client';

import React from 'react';
import errorIcon from '@frontend/jetlend-assets/icons/icon--500.svg';
import { getAssetSrc } from '@ui/utils/getAssetSrc';
import PlaceholderWrapper, { IProps as PlaceholderWrapperProps } from '../PlaceholderWrapper/PlaceholderWrapper';

export type IProps = Pick<PlaceholderWrapperProps, 'startPageLocation'|'image'|'hideButtons'>;

const ErrorPlaceholder: React.FC<IProps> = props => (
    <div className="d-flex d-flex--center d-flex--1 d-flex--v-center">
        <PlaceholderWrapper
            image={getAssetSrc(errorIcon)}
            startPageLocation={props.startPageLocation}
            needToRefresh
            hideButtons={props.hideButtons}
        >
            <h2 className="h-mt-20 h-mb-30">
                Возникла непредвиденная ошибка в работе приложения
            </h2>
        </PlaceholderWrapper>
    </div>
);

export default ErrorPlaceholder;
