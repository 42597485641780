'use client';

import { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import ErrorPlaceholder from '@frontend/jetlend-web-ui/src/components/ErrorPlaceholder/ErrorPlaceholder';
import styles from './error.module.scss';

export default function ErrorPage({
    error,
}: {
    error: Error & { digest?: string };
}) {
    // Sentry не логирует автоматически перехваченные ошибки, поэтому логируем их самостоятельно
    useEffect(() => {
        Sentry.captureException(error);
    }, [error]);

    return (
        <div className={styles['section__error']}>
            <ErrorPlaceholder startPageLocation="/" />
        </div>
    );
}